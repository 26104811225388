.MainTable{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
}

Table{
    width: 100%;
    margin-right: 5%;
}


.buttonDiv{
    display:flex;
    justify-content: space-between;
}

.buttonDiv>p{
    font-size: 1.9vw;
    font-weight:500;
}

@media screen and (min-width: 400px) {
  .buttonDiv{
    margin-left:80%;
    margin-top: 1%;
}
}

